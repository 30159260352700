import { render, staticRenderFns } from "./classifyList.vue?vue&type=template&id=2a9f783a&scoped=true&"
import script from "./classifyList.vue?vue&type=script&lang=js&"
export * from "./classifyList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9f783a",
  null
  
)

export default component.exports